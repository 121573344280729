import { ProjectBuilder } from '@/services'
import api from '@/api'

const state = {
  developerProjects: [],
  currentProject: {}
}

const getters = {
  developerProjects: state => state.developerProjects,
  currentProject: state => state.currentProject
}

const actions = {
  getDeveloperProjects ({ commit }) {
    api
      .getDeveloperProjects()
      .then(ProjectBuilder.decodeDeveloperProjectListResponse)
      .then(({ projects }) => {
        commit('setDeveloperProjects', projects)
      })
  },
  getCurrentProject ({ commit }, { projectId }) {
    commit('setCurrentProject', {})
    api
      .getProjectDetails(projectId)
      .then(ProjectBuilder.decodeProjectResponse)
      .then(project => {
        commit('setCurrentProject', project)
      })
  },
  createDeveloperProject ({ commit }, { name, email, userId }) {
    return new Promise(resolve => {
      ProjectBuilder.encodeCreateProjectRequest({ name, email, userId }).then(
        proto => {
          api
            .createDeveloperProject(proto)
            .then(ProjectBuilder.decodeCreateProjectResponse)
            .then(({ project }) => {
              commit('addCreatedDeveloperProject', project)
              resolve()
            })
        }
      )
    })
  }
}

const mutations = {
  setDeveloperProjects (state, developerProjects) {
    state.developerProjects = developerProjects
  },
  setCurrentProject (state, project) {
    state.currentProject = project
  },
  addCreatedDeveloperProject (state, project) {
    state.developerProjects.push(project)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
