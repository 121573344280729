/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/light");

var $root = ($protobuf.roots["default"] || ($protobuf.roots["default"] = new $protobuf.Root()))
.addJSON({
  com: {
    nested: {
      newmedia: {
        nested: {
          developer: {
            options: {
              swift_prefix: "Proto_"
            },
            nested: {
              ApplicationType: {
                values: {
                  ios: 0,
                  android: 1,
                  web: 2
                }
              },
              MinimalProjectData: {
                fields: {
                  projectId: {
                    type: "string",
                    id: 1
                  },
                  applicationTypes: {
                    rule: "repeated",
                    type: "ApplicationType",
                    id: 2
                  },
                  name: {
                    type: "string",
                    id: 3
                  },
                  email: {
                    type: "string",
                    id: 4
                  }
                }
              },
              AndroidApplication: {
                fields: {
                  applicationId: {
                    type: "string",
                    id: 1
                  },
                  applicationClientId: {
                    type: "string",
                    id: 2
                  },
                  applicationName: {
                    type: "string",
                    id: 3
                  },
                  applicationPackageName: {
                    type: "string",
                    id: 4
                  },
                  applicationSignatureSha1: {
                    type: "string",
                    id: 5
                  },
                  applicationIconUrl: {
                    type: "string",
                    id: 6
                  },
                  applicationReviewed: {
                    type: "bool",
                    id: 7
                  },
                  applicationSignaturesSha1: {
                    rule: "repeated",
                    type: "string",
                    id: 8
                  }
                }
              },
              IosApplication: {
                fields: {
                  applicationId: {
                    type: "string",
                    id: 1
                  },
                  applicationClientId: {
                    type: "string",
                    id: 2
                  },
                  applicationName: {
                    type: "string",
                    id: 3
                  },
                  applicationBundleId: {
                    type: "string",
                    id: 4
                  },
                  applicationRedirectUri: {
                    type: "string",
                    id: 5
                  },
                  applicationIconUrl: {
                    type: "string",
                    id: 6
                  },
                  applicationReviewed: {
                    type: "bool",
                    id: 7
                  }
                }
              },
              WebApplication: {
                fields: {
                  applicationId: {
                    type: "string",
                    id: 1
                  },
                  applicationClientId: {
                    type: "string",
                    id: 2
                  },
                  applicationName: {
                    type: "string",
                    id: 3
                  },
                  applicationWebsiteUrl: {
                    type: "string",
                    id: 4
                  },
                  applicationIconUrl: {
                    type: "string",
                    id: 5
                  },
                  applicationReviewed: {
                    type: "bool",
                    id: 6
                  }
                }
              },
              Application: {
                oneofs: {
                  applicationData: {
                    oneof: [
                      "android",
                      "ios",
                      "web"
                    ]
                  }
                },
                fields: {
                  android: {
                    type: "AndroidApplication",
                    id: 1
                  },
                  ios: {
                    type: "IosApplication",
                    id: 2
                  },
                  web: {
                    type: "WebApplication",
                    id: 3
                  }
                }
              },
              CreateAndroidApplication: {
                fields: {
                  applicationName: {
                    type: "string",
                    id: 1
                  },
                  applicationPackageName: {
                    type: "string",
                    id: 2
                  },
                  applicationSignatureSha1: {
                    type: "string",
                    id: 3
                  },
                  applicationIconUrl: {
                    type: "string",
                    id: 4
                  }
                }
              },
              CreateIosApplication: {
                fields: {
                  applicationName: {
                    type: "string",
                    id: 1
                  },
                  applicationBundleId: {
                    type: "string",
                    id: 2
                  },
                  applicationIconUrl: {
                    type: "string",
                    id: 3
                  }
                }
              },
              CreateWebApplication: {
                fields: {
                  applicationName: {
                    type: "string",
                    id: 1
                  },
                  applicationWebsiteUrl: {
                    type: "string",
                    id: 2
                  },
                  applicationIconUrl: {
                    type: "string",
                    id: 3
                  }
                }
              },
              DeveloperProjectListResponse: {
                fields: {
                  projects: {
                    rule: "repeated",
                    type: "MinimalProjectData",
                    id: 1
                  }
                }
              },
              CreateProjectRequest: {
                fields: {
                  name: {
                    type: "string",
                    id: 1
                  },
                  email: {
                    type: "string",
                    id: 2
                  },
                  userId: {
                    type: "string",
                    id: 3
                  }
                }
              },
              CreateProjectResponse: {
                fields: {
                  project: {
                    type: "MinimalProjectData",
                    id: 1
                  }
                }
              },
              CreateProjectError: {
                fields: {
                  code: {
                    type: "Code",
                    id: 1
                  },
                  userMessage: {
                    type: "string",
                    id: 2
                  }
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0
                    }
                  }
                }
              },
              DeleteProjectResponse: {
                fields: {
                  project: {
                    type: "MinimalProjectData",
                    id: 1
                  }
                }
              },
              DeleteProjectError: {
                fields: {
                  code: {
                    type: "Code",
                    id: 1
                  },
                  userMessage: {
                    type: "string",
                    id: 2
                  }
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                      PROJECT_NOT_FOUND: 1
                    }
                  }
                }
              },
              ProjectResponse: {
                fields: {
                  projectId: {
                    type: "string",
                    id: 1
                  },
                  applications: {
                    rule: "repeated",
                    type: "Application",
                    id: 2
                  },
                  email: {
                    type: "string",
                    id: 3
                  },
                  projectName: {
                    type: "string",
                    id: 4
                  }
                }
              },
              ProjectError: {
                fields: {
                  code: {
                    type: "Code",
                    id: 1
                  },
                  userMessage: {
                    type: "string",
                    id: 2
                  }
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                      NOT_FOUND: 1
                    }
                  }
                }
              },
              CreateApplicationRequest: {
                oneofs: {
                  applicationData: {
                    oneof: [
                      "ios",
                      "android",
                      "web"
                    ]
                  }
                },
                fields: {
                  ios: {
                    type: "CreateIosApplication",
                    id: 1
                  },
                  android: {
                    type: "CreateAndroidApplication",
                    id: 2
                  },
                  web: {
                    type: "CreateWebApplication",
                    id: 3
                  }
                }
              },
              CreateApplicationResponse: {
                fields: {
                  application: {
                    type: "Application",
                    id: 1
                  }
                }
              },
              CreateApplicationError: {
                fields: {
                  code: {
                    type: "Code",
                    id: 1
                  },
                  userMessage: {
                    type: "string",
                    id: 2
                  }
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                      NOT_FOUND: 1
                    }
                  }
                }
              },
              UpdateApplicationRequest: {
                fields: {
                  newName: {
                    type: "string",
                    id: 1
                  },
                  applicationType: {
                    type: "ApplicationType",
                    id: 2
                  }
                }
              },
              UpdateApplicationResponse: {
                fields: {
                  application: {
                    type: "Application",
                    id: 1
                  }
                }
              },
              UpdateApplicationError: {
                fields: {
                  code: {
                    type: "Code",
                    id: 1
                  },
                  userMessage: {
                    type: "string",
                    id: 2
                  }
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                      NOT_FOUND: 1
                    }
                  }
                }
              },
              DeleteApplicationError: {
                fields: {
                  code: {
                    type: "Code",
                    id: 1
                  },
                  userMessage: {
                    type: "string",
                    id: 2
                  }
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                      NOT_FOUND: 1
                    }
                  }
                }
              },
              WebApplicationInfo: {
                fields: {},
                nested: {
                  Response: {
                    fields: {
                      applicationClientId: {
                        type: "string",
                        id: 1
                      },
                      applicationName: {
                        type: "string",
                        id: 2
                      },
                      applicationImageUrl: {
                        type: "string",
                        id: 3
                      },
                      applicationWebsiteUrl: {
                        type: "string",
                        id: 4
                      },
                      projectName: {
                        type: "string",
                        id: 5
                      }
                    }
                  },
                  Error: {
                    fields: {
                      code: {
                        type: "Code",
                        id: 1
                      },
                      message: {
                        type: "string",
                        id: 2
                      }
                    },
                    nested: {
                      Code: {
                        values: {
                          UNKNOWN: 0,
                          APPLICATION_NOT_FOUND: 1,
                          PROJECT_NOT_FOUND: 2
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          profile: {
            nested: {
              model: {
                options: {
                  optimize_for: "LITE_RUNTIME",
                  swift_prefix: "Proto_"
                },
                nested: {
                  Gender: {
                    values: {
                      UNKNOWN: 0,
                      MALE: 1,
                      FEMALE: 2
                    }
                  },
                  Profile: {
                    fields: {
                      user: {
                        type: "user.User",
                        id: 1
                      },
                      gender: {
                        type: "Gender",
                        id: 2
                      },
                      email: {
                        type: "Email",
                        id: 3
                      },
                      countryCode: {
                        type: "string",
                        id: 17
                      },
                      phoneNumber: {
                        type: "string",
                        id: 4
                      },
                      birthDateInMillis: {
                        type: "int64",
                        id: 5
                      },
                      connectedToFacebook: {
                        type: "bool",
                        id: 9
                      },
                      facebookName: {
                        type: "string",
                        id: 19
                      },
                      hasPassword: {
                        type: "bool",
                        id: 10
                      },
                      blockedUserIds: {
                        rule: "repeated",
                        type: "string",
                        id: 11
                      },
                      hiddenUserIds: {
                        rule: "repeated",
                        type: "string",
                        id: 12
                      },
                      followedSuperuserIds: {
                        rule: "repeated",
                        type: "string",
                        id: 13
                      },
                      subscribedSuperuserIds: {
                        rule: "repeated",
                        type: "string",
                        id: 14
                      },
                      barcodeId: {
                        type: "string",
                        id: 18
                      },
                      barcodeColor: {
                        type: "string",
                        id: 7
                      },
                      church: {
                        type: "string",
                        id: 8
                      },
                      cloudSpace: {
                        type: "uint32",
                        id: 15
                      },
                      usedSpace: {
                        type: "uint32",
                        id: 16
                      }
                    },
                    nested: {
                      Email: {
                        fields: {
                          address: {
                            type: "string",
                            id: 1
                          },
                          verified: {
                            type: "bool",
                            id: 2
                          }
                        }
                      }
                    }
                  },
                  ProfileResponse: {
                    fields: {
                      profile: {
                        type: "Profile",
                        id: 1
                      }
                    }
                  },
                  ProfileUpdateRequest: {
                    fields: {
                      name: {
                        type: "string",
                        id: 1
                      },
                      username: {
                        type: "string",
                        id: 2
                      },
                      email: {
                        type: "string",
                        id: 3
                      },
                      avatarUrl: {
                        type: "string",
                        id: 4
                      },
                      password: {
                        type: "string",
                        id: 5
                      },
                      gender: {
                        type: "Gender",
                        id: 6
                      },
                      bio: {
                        type: "BioChange",
                        id: 7
                      },
                      privateAccount: {
                        type: "PrivacyChange",
                        id: 8
                      },
                      birthDate: {
                        type: "BirthDateChange",
                        id: 9
                      },
                      phoneNumber: {
                        type: "PhoneNumberChange",
                        id: 10
                      },
                      facebook: {
                        type: "FacebookChange",
                        id: 11
                      }
                    },
                    nested: {
                      PrivacyChange: {
                        fields: {
                          privateAccount: {
                            type: "bool",
                            id: 1
                          }
                        }
                      },
                      BirthDateChange: {
                        fields: {
                          birthDate: {
                            type: "int64",
                            id: 1
                          }
                        }
                      },
                      BioChange: {
                        fields: {
                          bio: {
                            type: "string",
                            id: 1
                          }
                        }
                      },
                      PhoneNumberChange: {
                        fields: {
                          OBSOLETECountryCode: {
                            type: "string",
                            id: 1,
                            options: {
                              deprecated: true
                            }
                          },
                          OBSOLETEPhone: {
                            type: "string",
                            id: 2,
                            options: {
                              deprecated: true
                            }
                          },
                          firebaseToken: {
                            type: "string",
                            id: 3
                          }
                        }
                      },
                      FacebookChange: {
                        fields: {
                          facebookToken: {
                            type: "string",
                            id: 1
                          }
                        }
                      }
                    }
                  },
                  ProfileUpdateResponse: {
                    fields: {
                      profile: {
                        type: "Profile",
                        id: 1
                      }
                    }
                  },
                  ProfileUpdateError: {
                    fields: {
                      code: {
                        type: "ErrorCode",
                        id: 1
                      },
                      message: {
                        type: "string",
                        id: 2
                      },
                      formErrors: {
                        rule: "repeated",
                        type: "FormValidationError",
                        id: 3
                      }
                    },
                    nested: {
                      FormValidationError: {
                        fields: {
                          code: {
                            type: "ErrorCode",
                            id: 1
                          },
                          message: {
                            type: "string",
                            id: 2
                          }
                        },
                        nested: {
                          ErrorCode: {
                            values: {
                              UNKNOWN: 0,
                              NAME: 1,
                              USERNAME: 2,
                              PHONE_NUMBER: 3,
                              PHONE_HASH: 4,
                              FACEBOOK_ID: 5,
                              PASSWORD: 6
                            }
                          }
                        }
                      },
                      ErrorCode: {
                        values: {
                          UNKNOWN: 0,
                          VALIDATION_FAILED: 1,
                          FB_CONNECTION_FAILURE: 2,
                          FIREBASE_AUTH_ERROR: 3,
                          EMAIL_ALREADY_CONFIRMED: 4
                        }
                      }
                    }
                  },
                  VerifyEmailRequest: {
                    fields: {}
                  },
                  VerifyEmailResponse: {
                    fields: {}
                  },
                  VerifyEmailError: {
                    fields: {
                      code: {
                        type: "ErrorCode",
                        id: 1
                      },
                      message: {
                        type: "string",
                        id: 2
                      }
                    },
                    nested: {
                      ErrorCode: {
                        values: {
                          UNKNOWN: 0,
                          EMAIL_TAKEN: 1,
                          NO_PENDING_VERIFICATION: 2
                        }
                      }
                    }
                  },
                  VerifyPhoneRequest: {
                    fields: {
                      countryCode: {
                        type: "string",
                        id: 1
                      },
                      phone: {
                        type: "string",
                        id: 2
                      }
                    }
                  },
                  VerifyPhoneResponse: {
                    fields: {}
                  },
                  VerifyPhoneError: {
                    fields: {
                      code: {
                        type: "ErrorCode",
                        id: 1
                      },
                      message: {
                        type: "string",
                        id: 2
                      }
                    },
                    nested: {
                      ErrorCode: {
                        values: {
                          UNKNOWN: 0,
                          INVALID_FORMAT: 1,
                          NUMBER_TAKEN: 2
                        }
                      }
                    }
                  },
                  ProfileBadgeResponse: {
                    fields: {
                      id: {
                        type: "string",
                        id: 1
                      },
                      title: {
                        type: "string",
                        id: 2
                      },
                      description: {
                        type: "string",
                        id: 3
                      },
                      imageUrl: {
                        type: "string",
                        id: 4
                      },
                      signature: {
                        type: "bool",
                        id: 5
                      }
                    }
                  },
                  ProfileBadgeError: {
                    fields: {
                      code: {
                        type: "ErrorCode",
                        id: 1
                      },
                      userMessage: {
                        type: "string",
                        id: 2
                      }
                    },
                    nested: {
                      ErrorCode: {
                        values: {
                          UNKNOWN: 0,
                          NOT_FOUND: 1
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  user: {
    options: {
      java_package: "com.appunite.user.model",
      java_multiple_files: true,
      optimize_for: "LITE_RUNTIME",
      java_generate_equals_and_hash: true,
      java_outer_classname: "UserModule",
      swift_prefix: "Proto_"
    },
    nested: {
      PresenceMessage: {
        oneofs: {
          presence: {
            oneof: [
              "online",
              "offline",
              "neverSeen"
            ]
          }
        },
        fields: {
          online: {
            type: "Online",
            id: 2
          },
          offline: {
            type: "Offline",
            id: 3
          },
          neverSeen: {
            type: "NeverSeen",
            id: 4
          }
        },
        nested: {
          Online: {
            fields: {}
          },
          Offline: {
            fields: {
              lastSeenInMillis: {
                rule: "required",
                type: "int64",
                id: 1
              }
            }
          },
          NeverSeen: {
            fields: {}
          }
        }
      },
      User: {
        fields: {
          userId: {
            rule: "required",
            type: "string",
            id: 1
          },
          name: {
            type: "string",
            id: 2
          },
          username: {
            type: "string",
            id: 3
          },
          postsCount: {
            type: "int32",
            id: 4
          },
          superuser: {
            type: "Superuser",
            id: 5
          },
          userBio: {
            type: "string",
            id: 6
          },
          avatarUrl: {
            type: "string",
            id: 7
          },
          avatarMainColor: {
            type: "int32",
            id: 8
          },
          privateAccount: {
            type: "bool",
            id: 9,
            options: {
              "default": false
            }
          },
          badgeIds: {
            rule: "repeated",
            type: "string",
            id: 10
          },
          presence: {
            type: "PresenceMessage",
            id: 11
          }
        },
        nested: {
          Superuser: {
            fields: {
              DEPRECATEDWebsiteUrl: {
                type: "string",
                id: 1
              },
              location: {
                type: "string",
                id: 2
              },
              coverUrl: {
                type: "string",
                id: 3
              },
              accountType: {
                type: "string",
                id: 4
              },
              followersCount: {
                type: "int32",
                id: 5
              },
              contactEmail: {
                type: "string",
                id: 6
              },
              contactPhoneNumber: {
                type: "string",
                id: 7
              }
            }
          }
        }
      },
      UserResponse: {
        fields: {
          user: {
            rule: "required",
            type: "user.User",
            id: 1
          }
        }
      },
      PostSuperusersSharersResponse: {
        fields: {
          userIds: {
            rule: "repeated",
            type: "string",
            id: 1
          },
          users: {
            rule: "repeated",
            type: "user.User",
            id: 2
          }
        }
      },
      GetUserByUsernameResponse: {
        fields: {
          userId: {
            type: "string",
            id: 1
          },
          users: {
            rule: "repeated",
            type: "user.User",
            id: 2
          }
        }
      },
      GetUserByUsernameError: {
        fields: {
          code: {
            type: "Code",
            id: 1
          },
          userMessage: {
            type: "string",
            id: 2
          }
        },
        nested: {
          Code: {
            values: {
              UNKNOWN: 0,
              USER_NOT_FOUND: 1
            }
          }
        }
      },
      GetConferenceUrlResponse: {
        fields: {
          conferenceUrl: {
            type: "string",
            id: 1
          }
        }
      },
      GetConferenceUrlError: {
        fields: {
          code: {
            type: "Code",
            id: 1
          },
          userMessage: {
            type: "string",
            id: 2
          }
        },
        nested: {
          Code: {
            values: {
              UNKNOWN: 0,
              CONFERENCE_URL_NOT_FOUND: 1
            }
          }
        }
      },
      UsersResponse: {
        fields: {
          users: {
            rule: "repeated",
            type: "User",
            id: 1
          }
        }
      }
    }
  }
});

module.exports = $root;
