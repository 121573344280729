import $root from '@/proto/proto-bundle'

const UserBuider = {
  decodeProfileResponse: ({ body }) => {
    return new Promise(resolve => {
      const container = $root.lookup('ProfileResponse')
      resolve(container.decode(new Uint8Array(body)))
    })
  },

  decodeUserResponse: ({ body }) => {
    return new Promise(resolve => {
      const container = $root.lookup('UserResponse')
      resolve(container.decode(new Uint8Array(body)))
    })
  }
}

export default UserBuider
