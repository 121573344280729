import Vue from 'vue'
import VueCookie from 'vue-cookie'
import VueResource from 'vue-resource'
import Vuelidate from 'vuelidate'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

Vue.use(VueCookie)
Vue.use(VueResource)
Vue.use(Vuelidate)
Vue.config.productionTip = false

Vue.http.interceptors.push((request, next) => {
  next(response => {
    if (!response.ok && response.status === 401) {
      store.dispatch('logout', false)
    }
  })
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
