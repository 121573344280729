import { ApplicationBuilder } from '@/services'
import api from '@/api'

const actions = {
  createAndroidApplication ({ commit }, { projectId, application }) {
    return new Promise(resolve => {
      ApplicationBuilder.encodeCreateAndroidApplicationRequest(application).then(
        proto => {
          api
            .createProjectApplication(projectId, proto)
            .then(response => {
              const decoded = ApplicationBuilder.decodeCreateApplicationResponse(response)
              resolve(decoded)
            })
        }
      )
    })
  },
  createIosApplication ({ commit }, { projectId, application }) {
    return new Promise(resolve => {
      ApplicationBuilder.encodeCreateIosApplicationRequest(application).then(
        proto => {
          api
            .createProjectApplication(projectId, proto)
            .then(response => {
              const decoded = ApplicationBuilder.decodeCreateApplicationResponse(response)
              resolve(decoded)
            })
        }
      )
    })
  },
  createWebApplication ({ commit }, { projectId, application }) {
    return new Promise(resolve => {
      ApplicationBuilder.encodeCreateWebApplicationRequest(application).then(
        proto => {
          api
            .createProjectApplication(projectId, proto)
            .then(response => {
              const decoded = ApplicationBuilder.decodeCreateApplicationResponse(response)
              resolve(decoded)
            })
        }
      )
    })
  },
  deleteApplication ({ commit, dispatch }, { applicationId, projectId }) {
    return new Promise(resolve => {
      api
        .deleteApplication(projectId, applicationId)
        .then(response => {
          dispatch('getCurrentProject', { projectId })
          resolve()
        })
    })
  }
}

export default {
  actions
}
