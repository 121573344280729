import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/store/user'
import project from '@/store/project'
import application from '@/store/application'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    project,
    application
  }
})
