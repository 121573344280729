const getRemotePaths = () => {
  const defaults = {
    development: {
      API_PATH: 'https://connect-kc.appunite.net/',
      AUTH_PATH: 'http://localhost:5050/',
      OAUTH_CLIENT_ID: 'com.kingschat'
    },

    production: {
      API_PATH: 'https://connect.kingsch.at/',
      AUTH_PATH: 'https://accounts.kingsch.at/',
      OAUTH_CLIENT_ID: 'com.kingschat'
    }
  }

  const ENV =
    window.ENV !== '$ENV'
      ? JSON.parse(window.ENV)
      : {
          ...defaults[process.env.NODE_ENV]
        }

  const constructAuthPath = () => {
    const accounts = ENV.AUTH_PATH
    let authPath = `${accounts}?client_id=${ENV.OAUTH_CLIENT_ID}`
    authPath = `${authPath}&scopes=["kingschat"]`
    authPath = `${authPath}&redirect_uri=${window.location.protocol}//${
      window.location.host
    }`
    return authPath
  }

  return {
    ...ENV,
    AUTH_PATH: constructAuthPath()
  }
}

export const REMOTE_PATHS = getRemotePaths()
