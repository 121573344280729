import Vue from 'vue'
import { UserBuilder } from '@/services'
import api from '@/api'
import { REMOTE_PATHS } from '@/constants/remote-paths.constant'

const state = {
  currentUser: JSON.parse(localStorage.getItem('currentUser')),
  userProfile: null
}

const getters = {
  currentUser: state => state.currentUser,
  userProfile: state => state.userProfile
}

const actions = {
  getCurrentUser ({ commit }) {
    api
      .getCurrentUser()
      .then(UserBuilder.decodeProfileResponse)
      .then(({ profile }) => {
        const { user, ...userProfile } = profile
        commit('setUserProfile', userProfile)
        commit('setCurrentUser', user)
      })
  },
  logout (keepRefreshToken) {
    Vue.prototype.$cookie.delete('accessToken', {
      domain: REMOTE_PATHS.COOKIE_DOMAIN
    })
    if (!keepRefreshToken) {
      Vue.prototype.$cookie.delete('refreshToken', {
        domain: REMOTE_PATHS.COOKIE_DOMAIN
      })
    }
    localStorage.removeItem('currentUser')
    window.location.href = `${REMOTE_PATHS.AUTH_PATH}${
      window.location.pathname
    }`
  }
}

const mutations = {
  setUserProfile (state, userProfile) {
    state.userProfile = userProfile
  },
  setCurrentUser (state, user) {
    localStorage.setItem('currentUser', JSON.stringify(user))
    state.currentUser = user
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
