import $root from '@/proto/proto-bundle'

const ProjectBuilder = {
  decodeDeveloperProjectListResponse: ({ body }) => {
    return new Promise(resolve => {
      const container = $root.lookup('DeveloperProjectListResponse')
      resolve(container.decode(new Uint8Array(body)))
    })
  },
  decodeCreateProjectResponse: ({ body }) => {
    return new Promise(resolve => {
      const container = $root.lookup('CreateProjectResponse')
      resolve(container.decode(new Uint8Array(body)))
    })
  },
  decodeProjectResponse: ({ body }) => {
    return new Promise(resolve => {
      const container = $root.lookup('ProjectResponse')
      resolve(container.decode(new Uint8Array(body)))
    })
  },
  encodeCreateProjectRequest: ({ name, email, userId }) => {
    return new Promise(resolve => {
      const builder = $root.lookup('CreateProjectRequest')
      const payload = builder.create({
        name: name,
        email: email,
        userId: userId
      })
      resolve(builder.encode(payload).finish())
    })
  }
}

export default ProjectBuilder
