import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

function getAccessToken () {
  return Vue.prototype.$cookie.get('accessToken')
}

const router = new Router({
  routes: [
    {
      path: '/',
      component: () => import('@/views/Authenticated.vue'),
      beforeEnter (to, from, next) {
        if (getAccessToken()) {
          next()
        } else {
          store.dispatch('logout', true)
        }
      },
      children: [
        {
          path: '',
          beforeEnter (to, from, next) {
            next({ name: 'projects' })
          }
        },
        {
          path: '/docs',
          name: 'docs',
          component: () => import('@/views/docs/Docs.vue')
        },
        {
          path: '/projects',
          name: 'projects',
          component: () => import('@/views/projects/Projects.vue')
        },
        {
          path: '/projects/:project_id',
          name: 'project_details',
          component: () => import('@/views/projects/ProjectDetails.vue')
        },
        {
          path: '/projects/:project_id/add_application',
          name: 'add_application',
          component: () => import('@/views/add-application/AddApplication.vue')
        }
      ]
    }
  ]
})

export default router
