import $root from '@/proto/proto-bundle'

const ApplicationBuilder = {
  encodeCreateAndroidApplicationRequest: ({ name, packageName, signature, iconUrl }) => {
    return new Promise(resolve => {
      const requestBuilder = $root.lookup('CreateApplicationRequest')
      const applicationBuilder = $root.lookup('CreateAndroidApplication')

      const payload = requestBuilder.create({
        android: applicationBuilder.create({
          applicationName: name,
          applicationPackageName: packageName,
          applicationSignatureSha1: signature,
          applicationIconUrl: iconUrl
        })
      })
      resolve(requestBuilder.encode(payload).finish())
    })
  },
  encodeCreateIosApplicationRequest: ({ name, bundleId, redirectUri, iconUrl }) => {
    return new Promise(resolve => {
      const requestBuilder = $root.lookup('CreateApplicationRequest')
      const applicationBuilder = $root.lookup('CreateIosApplication')

      const payload = requestBuilder.create({
        ios: applicationBuilder.create({
          applicationName: name,
          applicationBundleId: bundleId,
          applicationIconUrl: iconUrl
        })
      })
      resolve(requestBuilder.encode(payload).finish())
    })
  },
  encodeCreateWebApplicationRequest: ({ name, websiteUrl, iconUrl }) => {
    return new Promise(resolve => {
      const requestBuilder = $root.lookup('CreateApplicationRequest')
      const applicationBuilder = $root.lookup('CreateWebApplication')

      const payload = requestBuilder.create({
        web: applicationBuilder.create({
          applicationName: name,
          applicationWebsiteUrl: websiteUrl,
          applicationIconUrl: iconUrl
        })
      })
      resolve(requestBuilder.encode(payload).finish())
    })
  },
  decodeCreateApplicationResponse: ({ body }) => {
    return new Promise(resolve => {
      const container = $root.lookup('CreateApplicationResponse')
      resolve(container.decode(new Uint8Array(body)))
    })
  },
  decodeCreateApplicationError: ({ body }) => {
    return new Promise(resolve => {
      const container = $root.lookup('CreateApplicationError')
      resolve(container.decode(new Uint8Array(body)))
    })
  }
}

export default ApplicationBuilder
