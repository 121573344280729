import Vue from 'vue'
import { REMOTE_PATHS } from '@/constants/remote-paths.constant'

const getHeaders = () => {
  return {
    Accept: 'application/x-protobuf',
    Authorization: `Bearer ${Vue.cookie.get('accessToken')}`
  }
}

const postHeaders = () => {
  const token = Vue.cookie.get('accessToken')
  const headers = {
    Accept: 'application/x-protobuf',
    'Content-Type': 'application/x-protobuf'
  }
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  return headers
}

const constructPath = (path, base) => {
  if (base) {
    return `${base}${path}`
  } else {
    return `${REMOTE_PATHS.API_PATH}${path}`
  }
}

const api = {
  _get ({ path, params, base }) {
    return Vue.http.get(constructPath(path, base), {
      headers: getHeaders(),
      params,
      responseType: 'arraybuffer'
    })
  },
  _post ({ path, body, base }) {
    return Vue.http.post(constructPath(path, base), body, {
      headers: postHeaders(),
      responseType: 'arraybuffer'
    })
  },
  _put ({ path, body, base }) {
    return Vue.http.put(constructPath(path, base), body, {
      headers: postHeaders(),
      responseType: 'arraybuffer'
    })
  },
  _patch ({ path, body, base }) {
    return Vue.http.patch(constructPath(path, base), body, {
      headers: postHeaders(),
      responseType: 'arraybuffer'
    })
  },
  _delete ({ path, base }) {
    return Vue.http.delete(constructPath(path, base), {
      headers: postHeaders(),
      responseType: 'arraybuffer'
    })
  },
  getCurrentUser () {
    return this._get({ path: 'api/profile' })
  },
  getDeveloperProjects () {
    return this._get({ path: 'developer/api/projects' })
  },
  getProjectDetails (projectId) {
    return this._get({ path: `developer/api/projects/${projectId}` })
  },
  createDeveloperProject (proto) {
    return this._post({ path: 'developer/api/projects', body: proto })
  },
  createProjectApplication (projectId, proto) {
    return this._post({ path: `developer/api/projects/${projectId}/applications`, body: proto })
  },
  deleteApplication (projectId, applicationId) {
    return this._delete({ path: `developer/api/projects/${projectId}/applications/${applicationId}` })
  },
  uploadFile (file, progress) {
    const formData = new FormData()
    formData.append('media[file]', file)
    formData.append('media[content_type]', file.type)
    formData.append('media[fetch_metadata]', true)

    return Vue.http.post(`${REMOTE_PATHS.API_PATH}api/media`, formData, {
      headers: {
        Authorization: `Bearer ${Vue.prototype.$cookie.get('accessToken')}`
      },
      responseType: 'json',
      progress: progress
    })
  }
}

export default api
